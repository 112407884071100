<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>全渠道分销</el-breadcrumb-item>
            <el-breadcrumb-item>渠道商业绩</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 表头 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
               <label>渠道商：</label>
                  <el-select v-model="id"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                        v-for="item in channelData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                     </el-option>
                  </el-select>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <div class="table-box">
            <!-- 表格 -->
            <el-table
               ref="mainTable"
               :data="tableData"
               style="width: 100%"
               :stripe="true"
               border fit>
               <el-table-column prop="id" label="序号" width="50">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="name" label="渠道商名称" min-width="60"></el-table-column>
               <el-table-column label="等级" min-width="30">
                  <template slot-scope="scope">
                     {{ scope.row.level | filterLevel(that) }}
                  </template>
               </el-table-column>
               <el-table-column label="渠道商类型" min-width="50">
                  <template slot-scope="scope">
                     {{ scope.row.type | filterType(that) }}
                  </template>
               </el-table-column>
               <el-table-column label="业务类型" min-width="40">预售券</el-table-column>
               <el-table-column prop="couponCode" label="编号" min-width="50"></el-table-column>
               <el-table-column prop="mainTitle" label="业务名称" min-width="40"></el-table-column>
               <el-table-column prop="presellPrice" label="售价" min-width="40"></el-table-column>
               <el-table-column prop="distributorPrice" label="分润价格" min-width="40"></el-table-column>
               <el-table-column prop="realName" label="姓名" min-width="40"></el-table-column>
               <el-table-column prop="mobile" label="电话" min-width="60"></el-table-column>
               <el-table-column prop="wecharId" label="微信号" min-width="50"></el-table-column>
               <el-table-column prop="state" label="状态" min-width="50">
                  <template slot-scope="scope">
                     {{ scope.row.state | filterState(that) }}
                  </template>
               </el-table-column>
               <el-table-column prop="qudao" label="购买渠道" min-width="50"></el-table-column>
               <el-table-column prop="buyingTime" label="购买时间" min-width="50"></el-table-column>
               <el-table-column :label="$t('msg.operating')" fixed="right" min-width="60">
                  <template slot-scope="scope">
                     <el-button type="text" @click="handleDetail(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.detail')">详情</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </div>
         <!-- 详情 -->
         <el-form label-width="105px" class="popup-box">
            <el-dialog title="预售券详情" :visible.sync="detailVisible" width="660px">
               <el-row class="text-list">
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">购买人姓名</span>{{ detail.realName }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">购买人电话</span>{{ detail.mobile }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">证件号码</span></p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">微信号</span>{{ detail.wecharId }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">购买渠道</span></p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">购买时间</span>{{ detail.buyingTime }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">状态</span>{{ detail.state | filterState(that) }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">核销时间</span></p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">预售券名称</span>{{ detail.mainTitle }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">预售券简称</span>{{ detail.subTitle }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">预售价格</span>{{ detail.presellPrice }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">商品原价</span>{{ detail.marketPrice }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">发行量</span>{{ detail.issueCount }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">单用户限购数</span>{{ detail.limitQuantity }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">开始时间</span>{{ detail.activityBeginTime }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">结束时间</span>{{ detail.activityEndTime }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">积分兑换</span>{{ detail.isIntegral | filterIsIntegral(that) }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">过期退款方式</span>{{ detail.refundType | filterRefundType(that) }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p><span class="width-100">有效期</span>{{ detail.validDate }}</p>
                     </el-col>
                     <el-col :span="12">
                        <p><span class="width-100">使用方式</span>{{ detail.useType | filterUseType(that) }}</p>
                     </el-col>
                  </el-row>
                  <el-row>
                     <el-col :span="24">
                        <p><span class="width-100">预购券详情</span>{{ detail.remark }}</p>
                     </el-col>
                  </el-row>
               </el-row>
               <el-row class="btm-btn">
                  <el-button class="bg-gradient" @click="detailVisible = false">关闭</el-button>
               </el-row>
            </el-dialog>
         </el-form>
      </el-row>
   </section>
</template>

<script>
import { urlObj } from "@/api/interface"
import { distributor }  from "@/api/interface/business"
import { mapState } from 'vuex'
import { getDict } from "@/common/js/common"
export default {
   data(){
      return{
         tableData: [],         // 总数
         channelData: [],       // 渠道商列表
         id: '',                // 渠道商ID
         limit: 0,              // 每页数
         total: 0,              // 总页数
         page: 1,               // 当前页
         detailVisible: false,  // 详情弹窗
         that: this,
         detail: {
            realName: '',
            mobile: '',
            wecharId: '',
            buyingTime: '',
            state: '',
            mainTitle: '',
            subTitle: '',
            presellPrice: '',
            marketPrice: '',
            issueCount: '',
            limitQuantity: '',
            activityBeginTime: '',
            activityEndTime: '',
            refundType: '',
            validDate: '',
            useType: '',
            remark: ''
         },
         // 提示文本
         del_success: "删除成功！",
         add_success: "添加成功！",
         update_success: '修改成功！',
         userName_no_null: '用户名不能为空！',
         mobile_no_null: '手机号不能为空！',
         nickName_no_null: '昵称不能为空！',
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！',
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      getDict(['distributor-level', 'distributor-type', 'coupon-use-state', 'refund-type', 'coupon-usage'])
      this.getDistributorSales()
      this.getDistributors()
   },
   methods: {
      // 获取渠道商业绩列表
      getDistributorSales(){
         const url = distributor.distributorSalesList + `?page=${this.page}&limit=${this.limit}`
         const param = { hotelId: this.hotelInfo.id, id: this.id }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.total = res.total
               this.tableData = res.records
            }
         })
      },
      // 获取渠道商列表
      getDistributors(){
         const url = distributor.distributorPage + `?page=1&limit=99999`
         const param = { hotelId: this.hotelInfo.id }
         this.$axios.post(url, param).then(res => res.success && (this.channelData = res.records))
      },
      // 改变每页数
      pageChange(num){
         this.limit = num
         this.getDistributorSales()
      },
      // 改变当前页
      handlePaging(num){
         this.page = num
         this.getDistributorSales()
      },
      // 查询渠道商业绩
      handleQuery(bool){
         if (bool) return this.getDistributorSales()
         this.id = ''
      },
      // 详情
      handleDetail(row){
         const url = distributor.couponPresell + `/${row.couponId}`
         this.$axios.get(url, {}).then(res => {
            if (res.success) for (let k in this.detail) k in res.records && (this.detail[k] = res.records[k])
         })
         for (let k in this.detail) k in row && (this.detail[k] = row[k])
         this.detailVisible = true
      }
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         if (newVal.id !== oldVal.id && oldVal) {
            this.getDistributorSales()
            this.getDistributors()
         }
      }
   },
   filters: {
      filterLevel(val, that){
         if (val && that.dictData['distributor-level']) return that.dictData['distributor-level'][val] || val
      },
      filterType(val, that){
         if (val && that.dictData['distributor-type']) return that.dictData['distributor-type'][val] || val
      },
      filterState(val, that){
         if (val && that.dictData['coupon-use-state']) return that.dictData['coupon-use-state'][val] || val
      },
      filterRefundType(val, that){
         if (val && that.dictData['refund-type']) return that.dictData['refund-type'][val] || val
      },
      filterUseType(val, that){
         if (val && that.dictData['coupon-usage']) return that.dictData['coupon-usage'][val] || val
      },
      filterIsIntegral(val, that){
         if (val && that.dictData['point-exchange']) return that.dictData['point-exchange'][val] || val
      }
   }
}
</script>

<style lang="scss" scoped>
.popup-box{
   .tit{
      text-align: center;
      h6{ font-size: 18px; margin-bottom: 10px }
      p{ font-size: 16px }
   }
   .right-price{ text-align: right; span{ font-size: 26px; color: red } }
   .table-box{ width: 70%; margin: 0 auto }
   .text-list{
      margin: 20px auto 0; line-height: 30px; color: #666666;
      span{ display: inline-block; text-align: right; margin-right: 15px; color: #222222 }
   }
   .btm-btn{ margin: 20px 0 0 0; text-align: center }
}
</style>
